// QueueRender.jsx
import { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

import { queue as queueDebug } from '../resource/debug.js';

export const childStates = {
  STARTED: 'queue-started',
  WAITING: 'queue-waiting',
};

const loadingStates = {
  INIT: 'init',
  PACKAGE_LOADED: 'package-loaded',
  QUEUE_CREATED: 'queue-created',
  PROMISE_ADDED: 'promise-added',
  PROMISE_STARTED: 'promise-started',
  PROMISE_RESOLVED: 'promise-resolved',
  PROMISE_REJECTED: 'promise-rejected',
};

let PQueue = null;
let loadPQueuePromise = null;
let taskIndex = 0;
const queues = {};
const debugLog = queueDebug.extend('log:QueueRender');

const isServer = typeof window === 'undefined';
if (!isServer) {
  window.__QueueRender_queues__ = queues;
}

export const QueueRender = ({
  queueId = 'default',
  pQueueOptions = {},
  taskOptions = {},
  taskId = '',
  render = () => null,
}) => {
  const [loadingState, setLoadingState] = useState(loadingStates.INIT);
  const [childState, setChildState] = useState(childStates.WAITING);
  const corePromise = useRef();
  const corePromiseResolve = useRef();
  const corePromiseReject = useRef();
  const log = useRef(debugLog.extend(taskId || queueId));
  const localTaskIndex = useRef();

  // load p-queue package
  useEffect(() => {
    log.current('init load p-queue', { PQueue });
    if (PQueue) {
      setLoadingState(() => loadingStates.PACKAGE_LOADED);
      return;
    }
    const loadPQueue = async () => {
      log.current('loadPQueue() init');
      if (!loadPQueuePromise) {
        log.current('loadPQueue() new load promise');
        // To import ESM package, we have to use await import.
        loadPQueuePromise = import('p-queue');
        PQueue = (await loadPQueuePromise).default;
        loadPQueuePromise = null;
      } else {
        log.current('loadPQueue() reuese load promise');
        PQueue = (await loadPQueuePromise).default;
      }
      log.current('loadPQueue() done loading');
      setLoadingState(() => loadingStates.PACKAGE_LOADED);
    };
    loadPQueue();
  }, []);

  // create queue
  useEffect(() => {
    log.current('init create queue', { queueId, PQueue, loadingState });
    if (!PQueue) {
      return;
    }
    if (loadingStates.PACKAGE_LOADED === loadingState) {
      if (queues[queueId]) {
        log.current('reuse queue', { queueId, pQueueOptions });
        setLoadingState(() => loadingStates.QUEUE_CREATED);
        return;
      }
      log.current('create new queue', { queueId, pQueueOptions });
      // TODO: enable update pQueueOptions when it changed
      queues[queueId] = new PQueue(pQueueOptions);
      setLoadingState(() => loadingStates.QUEUE_CREATED);
    }
  }, [queueId, pQueueOptions, loadingState]);

  // create and insert promise into queue
  useEffect(() => {
    log.current('init create promise', {
      queueId,
      loadingState,
      corePromise: corePromise.current,
      queue: queues[queueId],
    });
    if (
      queues[queueId] &&
      [loadingStates.QUEUE_CREATED, loadingStates.INIT].includes(
        loadingState
      ) &&
      !corePromise.current
    ) {
      log.current('create promise', { queueId, taskIndex });
      localTaskIndex.current = taskIndex;
      taskIndex = taskIndex + 1;
      corePromise.current = new Promise((resolve, reject) => {
        corePromiseResolve.current = resolve;
        corePromiseReject.current = reject;
      });

      // need to set before promise created
      setLoadingState(() => loadingStates.PROMISE_ADDED);

      queues[queueId].add(async () => {
        log.current('promise started', { taskIndex: localTaskIndex.current });
        setLoadingState(() => loadingStates.PROMISE_STARTED);
        setChildState(() => childStates.STARTED);
        try {
          await corePromise.current;
          log.current('promise resolved', {
            taskIndex: localTaskIndex.current,
          });
          setLoadingState(() => loadingStates.PROMISE_RESOLVED);
        } catch (error) {
          log.current('promise rejected', {
            error,
            taskIndex: localTaskIndex.current,
          });
          setLoadingState(() => loadingStates.PROMISE_REJECTED);
        }
        // TODO: implement abort signal
      }, taskOptions);
    }
  }, [queueId, loadingState, taskOptions]);

  // cleanup promise
  useEffect(() => {
    const logRef = log.current;
    return () => {
      logRef('clean promise', { queueId });
      corePromiseResolve.current?.();
    };
  }, [queueId]);

  return render({
    loadingState,
    childState,
    taskIndex: localTaskIndex.current,
    resolve: corePromiseResolve.current,
    reject: corePromiseReject.current,
  });
};

QueueRender.propTypes = {
  queueId: PropTypes.string,
  pQueueOptions: PropTypes.object,
  taskOptions: PropTypes.object,
  taskId: PropTypes.string,
  render: PropTypes.func,
};

export default QueueRender;
